"use client";

import { Pages } from "@/common/pages.modal";
import { SendHeightToParent } from "@/common/useSendHeightToParent";
import { ErrorMoadlWrapper, Modal } from "@/components/Modal/Modal";
import { clearFormVar, hideGeneralError, layoutVar } from "@/store";
import { useReactiveVar } from "@apollo/client";
import styled from "@emotion/styled";
import { Dialog } from "@mui/material";
import { useRouter } from "next/navigation";
import { FC, Suspense, useRef } from "react";
import Header from "./Header";

const StyledMain = styled.main`
  display: flex;
  flex-direction: column;
  :focus {
    outline: 0;
  }
`;

const Layout: FC<any> = ({ children }) => {
  const mainRef = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const { openGeneralError } = useReactiveVar(layoutVar);
  return (
    <>
      <Suspense>
        <SendHeightToParent />
      </Suspense>
      <StyledMain id="mainContent" tabIndex={-1} ref={mainRef}>
        {!layoutVar().hideHeader && <Header />}
        {children}
      </StyledMain>
      <ErrorMoadlWrapper>
        <Dialog
          open={openGeneralError}
          onClose={() => hideGeneralError()}
          aria-labelledby="תפריט תקלות"
          aria-describedby="תפריט תקלות במידה ויש תקלה"
        >
          <Modal
            setError={() => {}}
            handleClose={() => {
              clearFormVar();
              router.push(`/${Pages.onBoarding}`);
              hideGeneralError();
            }}
            msgHeader="הופה, יש תקלה קלה:"
            msgDiscription="מצטערים אבל צריך לנסות שוב יותר מאוחר"
          />
        </Dialog>
      </ErrorMoadlWrapper>
    </>
  );
};

export default Layout;
